import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Fade,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  completeHelperId,
  setActiveHelperId,
} from "../store/features/base/settingsSlice";

const HelperBox = ({
  children,
  title,
  description,
  helperId = "",
  placement = "top",
  priority = 0, //0-100
}) => {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  const isFirstAccess = useSelector((state) => state.settings.isFirstAccess);
  const helpers = useSelector((state) => state.settings.helpers);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    dispatch(completeHelperId(helperId));
    dispatch(setActiveHelperId(null));
  };

  useEffect(() => {
    let timeout;

    if (
      !helpers?.activeHelperId &&
      !helpers?.completed?.includes(helperId) &&
      !isFirstAccess
    ) {
      timeout = setTimeout(() => {
        setOpen(true);
        dispatch(setActiveHelperId(helperId));
      }, priority * 10);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [helpers?.completed, helpers?.activeHelperId, isFirstAccess]);

  return (
    <div ref={anchorRef} style={{ display: "inline-block" }}>
      {children}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={placement}
        disablePortal
        transition
        sx={{ zIndex: (t) => t.zIndex.tooltip }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 5], // Distância do Popper em relação ao elemento filho
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                position: "relative",
                padding: 2,
                borderRadius: 5,
                boxShadow: 5,
                minWidth: 300,
                maxWidth: 400,
              }}
            >
              <IconButton
                onClick={handleClose}
                size="small"
                color="inherit"
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  transition: "none",
                  opacity: 0.7,
                  ":hover": {
                    opacity: 1,
                  },
                }}
              >
                <Close sx={{ fontSize: "20px" }} />
              </IconButton>
              <Typography
                gutterBottom
                variant="h5"
                fontSize={"1.3rem"}
                fontWeight={700}
                color="primary"
              >
                {title}
              </Typography>
              <Typography
                color="textSecondary"
                fontSize={".95rem"}
                fontWeight={"500"}
              >
                {description}
              </Typography>
              <Stack mt={2} direction={"row"} alignItems={"center"}>
                <Box flex={1} />
                <Button
                  sx={{ transition: "none" }}
                  onClick={handleClose}
                  variant="contained"
                  disableElevation
                >
                  Entendi
                </Button>
              </Stack>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default memo(HelperBox);
