import {
  Assignment,
  AssignmentOutlined,
  CurrencyExchange,
  CurrencyExchangeOutlined,
  Person,
  PersonOutlineOutlined
} from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import React, { memo, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useRouteContext from "../../../../../hooks/useRouteContext";
import SidebarRoundedItem from "../../../components/SidebarRoundedItem";
import CreationFab from "./components/CreationFab";
import RecentsList from "./components/RecentsList";

const ReportsSidebar = ({ expanded }) => {
  const { role } = useRouteContext();

  const [params, setParams] = useSearchParams();

  const reportFilter = params.get("reportFilter") || "all";

  const handleFilterChange = useCallback(
    (filter) => () => {
      params.set("reportFilter", filter);
      setParams(params);
    },
    [params, role]
  );

  useEffect(() => {
    if (role !== "personal") {
      params.set("reportFilter", "all");
      setParams(params);
    }
  }, [role]);

  if (!role) return;

  return (
    <>
      <Box mb={2} pl={0.1}>
        <CreationFab role={role} expanded={expanded} />
      </Box>
      <div>
        <SidebarRoundedItem
          disableLink
          isSelected={reportFilter === "all"}
          Icon={AssignmentOutlined}
          ActiveIcon={Assignment}
          title={"Todos"}
          onClick={handleFilterChange("all")}
          showTooltip={!expanded}
        />
        {role === "personal" && (
          <>
            <SidebarRoundedItem
              disableLink
              isSelected={reportFilter === "for_me"}
              Icon={PersonOutlineOutlined}
              ActiveIcon={Person}
              title={"Gerados por mim"}
              onClick={handleFilterChange("for_me")}
              showTooltip={!expanded}
            />
            <SidebarRoundedItem
              disableLink
              isSelected={reportFilter === "refund"}
              Icon={CurrencyExchangeOutlined}
              ActiveIcon={CurrencyExchange}
              title={"Reembolsos"}
              onClick={handleFilterChange("refund")}
              showTooltip={!expanded}
            />
          </>
        )}
      </div>
      {expanded && (
        <>
          <Divider sx={{ mt: 1 }} />
          <Box
            pt={1}
            mr={-1}
            mb={-3}
            pb={5}
            flex={1}
            flexBasis={0}
            overflow={"auto"}
            className="hover-scrollbar"
          >
            <RecentsList role={role} />
          </Box>
        </>
      )}
    </>
  );
};

export default memo(ReportsSidebar);
