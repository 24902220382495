import { Circle, DeleteOutlineOutlined } from "@mui/icons-material";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  IconButton,
  LinearProgress,
  MenuItem,
  Radio,
} from "@mui/material";
import { memo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { clearCache } from "../../api/cache";
import { HttpClient } from "../../api/httpClient";
import TextInput from "../../components/inputs/text-input";
import CloseableDialogTitle from "../../modals/CloseableDialogTitle";
import { getExpensesByResult } from "../../modals/expenses/failed-resume/utils";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openElement } from "../../store/features/base/modalsSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { updateManyExpensesByItems } from "../../store/features/expensesSlice";
import { EXPENSES_STATUS_UTILS } from "../../utils/initialStates";

const EditExpensesStatusWrapper = ({
  role,
  renderComponent = ({ openModal = () => {} }) => {},
  onSuccess = () => {},
}) => {
  const dispatch = useDispatch();
  const [expensesIds, setExpensesIds] = useState([]);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const abortControllerRef = useRef(null);

  const resetState = () => {
    setStatus("");
    setLoading(false);
    setExpensesIds([]);
  };

  const onClose = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    resetState();
    setOpened(false);
  };

  const handleSave = async () => {
    setLoading(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const { data: responseData } = await HttpClient.put({
        url: "/expenses",
        body: {
          ids: expensesIds,
          fields: {
            status: status || undefined,
            rejectionReason: status === "rejected" ? comment : null,
          },
        },
        signal: abortController.signal,
        params: {
          role,
        },
      });
      clearCache("/expenses");

      const { allOk, successNumber, withErrors, failed, successIds, success } =
        getExpensesByResult(responseData);
      if (allOk) {
        dispatch(
          openSnackbar({
            message:
              successNumber === 1
                ? "Despesa atualizada"
                : `${successNumber} despesas atualizadas`,
          })
        );
      } else if (withErrors) {
        dispatch(
          openElement({
            name: "modalExpensesFailedResume",
            payload: {
              expenses: failed,
              title: "Algumas despesas não foram editadas",
              role,
            },
          })
        );
      }

      dispatch(
        updateManyExpensesByItems({
          role,
          expenses: success || [],
        })
      );
      onSuccess();
      onClose();
    } catch (error) {
      dispatch(setError({ error }));
    }
    setLoading(false);
  };

  return (
    <>
      {renderComponent({
        openModal: (expensesIds) => {
          setExpensesIds(expensesIds);
          setOpened(true);
        },
      })}
      {opened && (
        <Dialog fullWidth open={opened} maxWidth={"xs"}>
          {Boolean(opened) && (
            <>
              {loading && <LinearProgress />}
              <CloseableDialogTitle
                sx={{
                  fontSize: "1.2rem",
                }}
                onClose={onClose}
              >
                Para qual status?
              </CloseableDialogTitle>
              <DialogContent sx={{ pb: 5 }}>
                {Object.keys(EXPENSES_STATUS_UTILS.labels)
                  ?.filter((item) => !["pending"].includes(item))
                  .map((item) => (
                    <MenuItem
                      key={item}
                      disabled={loading}
                      selected={item === status}
                      onClick={() => setStatus(item)}
                      sx={{
                        gap: 1.3,
                        px: 1,
                        fontWeight: "600",
                        fontSize: ".95rem",
                        borderRadius: 100,
                      }}
                    >
                      <Radio checked={item === status} />
                      <Circle
                        color={EXPENSES_STATUS_UTILS.colors[item]}
                        sx={{
                          fontSize: ".6rem",
                        }}
                      />
                      {EXPENSES_STATUS_UTILS.labels[item]}
                    </MenuItem>
                  ))}
                <FormHelperText sx={{ mt: 2 }}>
                  Apenas os usuários podem enviar suas despesas para aprovação.
                </FormHelperText>
                <Collapse in={status === "rejected"} unmountOnExit>
                  <TextInput
                    autoFocus
                    hiddenLabel
                    label={""}
                    value={comment}
                    onChange={setComment}
                    margin={"normal"}
                    placeholder={"Informe um motivo (opcional)"}
                    multiline
                    rows={4}
                    RightIcon={() =>
                      Boolean(comment) && (
                        <IconButton size="small" onClick={() => setComment("")}>
                          <DeleteOutlineOutlined fontSize="small" />
                        </IconButton>
                      )
                    }
                    variant={"filled"}
                  />
                </Collapse>
              </DialogContent>

              <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  disabled={!status || loading}
                  variant="contained"
                  disableElevation
                  onClick={handleSave}
                >
                  Alterar
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default memo(EditExpensesStatusWrapper);
