import { createSelector, createSlice, nanoid } from "@reduxjs/toolkit";
import getAccountLogoUrl from "../../utils/functions/getAccountLogoUrl";
import { curr } from "../../utils/more/currency_country";

const initialState = {
  accountError: null,
  checkingAccess: true,
  accountId: "",
  currency: "",
  baseInfo: {
    roles: {},
  },
  accountHasLogo: false,
  hasPhoto: false,
  accountStatus: "",
  isApprover: false,
  isMaster: false,
  isAdmin: false,
  synced: false,
  syncing: false,
  logoVersion: 0,
  accessExpiresAt: null,
  lastSync: null,
  receiptsAccessToken: {
    token: null,
    expiresAt: null,
  },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    saveAccountData(state, { payload }) {
      state.accountId = payload.account?._id || state.accountId;
      state.baseInfo = {
        ...state.baseInfo,
        ...(payload || {}),
      };
    },
    updateSynced(state) {
      state.synced = true;
      state.lastSync = new Date().toISOString();
    },
    updateLastSync(state, action) {
      state.lastSync = new Date(action.payload).toISOString();
    },
    resetSynced(state) {
      state.synced = initialState.synced;
      state.lastSync = initialState.lastSync;
    },
    setAccessExpiresAt(state, { payload }) {
      state.accessExpiresAt = new Date(payload).toISOString();
    },

    updateBaseInfo(state, { payload }) {
      state.baseInfo = {
        ...state.baseInfo,
        ...payload,
      };
    },

    updateLogoVersion(state, { payload }) {
      state.logoVersion = nanoid(4);
    },

    updateNotificationsSettings(state, { payload }) {
      state.baseInfo = {
        ...state.baseInfo,
        notifications: {
          ...(state.baseInfo?.notifications || {}),
          ...(payload || {}),
        },
      };
    },

    updatePhotoVersion(state, { payload }) {
      state.baseInfo.profile_photo_version = payload || nanoid(4);
    },
    setAccountError(state, { payload }) {
      state.accountError = payload;
    },
    setHasPhoto(state, { payload }) {
      state.hasPhoto = Boolean(payload);
    },
  },
});

export const {
  saveAccountData,
  resetSynced,
  updateSynced,
  setAccessExpiresAt,
  setSyncing,
  updateLastSync,
  setAccountError,
  toggleCheckingAccess,
  updatePhotoVersion,
  setReceiptsAccessToken,
  setHasPhoto,
  updateBaseInfo,
  updateNotificationsSettings,
  updateLogoVersion,
} = accountSlice.actions;

export default accountSlice.reducer;

export const selectAccountError = (state) => state.account.accountError;
export const selectUserBaseInfo = (state) => state.account.baseInfo;

//roles

export const selectIsApprover = createSelector(
  [(state) => state.account.baseInfo?.roles],
  (roles) => {
    return Boolean(roles?.approver?.enabled);
  }
);
export const selectIsAdmin = createSelector(
  [(state) => state.account.baseInfo?.roles],
  (roles) => {
    return Boolean(roles?.admin?.enabled);
  }
);
export const selectIsFinancial = createSelector(
  [(state) => state.account.baseInfo?.roles],
  (roles) => {
    return Boolean(roles?.financial?.enabled);
  }
);
export const selectIsDirector = createSelector(
  [(state) => state.account.baseInfo?.roles],
  (roles) => {
    return Boolean(roles?.director?.enabled);
  }
);
export const selectIsPersonalOnly = createSelector(
  [selectIsApprover, selectIsFinancial, selectIsDirector],
  (approver, financial, director) => {
    return !approver && !financial && !director;
  }
);
export const selectIsInsights = createSelector(
  [(state) => state.account.baseInfo?.roles],
  (roles) => {
    return Boolean(roles?.insights?.enabled);
  }
);
export const selectIsBilling = createSelector(
  [(state) => state.account.baseInfo?.roles],
  (roles) => {
    return Boolean(roles?.billing?.enabled);
  }
);

export const selectIsMaster = createSelector(
  [(state) => state.account.baseInfo?.roles],
  (roles) => {
    return Boolean(roles?.master);
  }
);

export const selectAccountCurrency = createSelector(
  [
    (state) => state.account.baseInfo.currency,
    (state, enableFormatter) => enableFormatter,
  ],
  (currency, enableFormatter) => {
    if (enableFormatter) {
      return curr(currency);
    }
    return currency;
  }
);

export const selectAccountStatus = (state) =>
  state.account.baseInfo?.account?.status;

export const getAccountLogoURL = (state) =>
  getAccountLogoUrl(state.account.accountId);

export const selectHasAccountLogo = createSelector(
  [(state) => state.account.baseInfo?.settings],
  (settings) => {
    return Boolean(settings?.hasLogo);
  }
);
export const selectHasProfilePhoto = createSelector(
  [(state) => state.account.baseInfo?.has_profile_photo],
  (hasPhoto) => {
    return Boolean(hasPhoto);
  }
);

export const selectAccountGroups = createSelector(
  [selectUserBaseInfo],
  (baseInfo) => {
    return baseInfo?.groups || [];
  }
);

export const selectSapConnections = createSelector(
  [selectUserBaseInfo],
  (baseInfo) => {
    return baseInfo?.settings?.sap?.connections || [];
  }
);

//Senior
export const selectSeniorIsActive = createSelector(
  [selectUserBaseInfo],
  (baseInfo = {}) => {
    return Boolean(baseInfo?.settings?.senior?.enabled);
  }
);

//SAP selectors
export const selectSapIsActive = createSelector(
  [selectUserBaseInfo],
  (baseInfo = {}) => {
    return Boolean(baseInfo?.settings?.sap?.isActive);
  }
);
export const selectDefaultSapConnection = createSelector(
  [selectSapConnections],
  (connections = []) => {
    return connections?.find((item) => item?.isDefault) || null;
  }
);

export const selectRequiredFields = createSelector(
  [selectUserBaseInfo],
  (baseInfo) => {
    return baseInfo?.settings?.requiredFields || [];
  }
);

export const selectRequiredReceipt = createSelector(
  [selectUserBaseInfo],
  (baseInfo) => {
    return Boolean(baseInfo?.settings?.requiredReceipt);
  }
);

export const selectEnableMultipleReceipts = createSelector(
  [selectUserBaseInfo],
  (baseInfo) => {
    return Boolean(baseInfo?.settings?.enableMultipleReceipts);
  }
);
export const selectHasMultipleGroups = createSelector(
  [selectAccountGroups],
  (groups) => {
    return groups?.length > 1;
  }
);
