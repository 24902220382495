import { CheckCircle, CopyAllOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";

const CopyButtonText = ({ value }) => {
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();

  if (!value) {
    return undefined;
  } else {
    return (
      <Tooltip title="Copiar para a área de transferência">
        <IconButton
          size="small"
          disableRipple={copied}
          sx={{ position: "relative" }}
          onClick={() => {
            if (copied) return;
            setCopied(true);
            navigator.clipboard.writeText(value).then(() => {
              setTimeout(() => {
                setCopied(false);
              }, 1400);
            });
            dispatch(
              openSnackbar({
                message: "Copiado para a área de transferência",
              })
            );
          }}
        >
          <CheckCircle
            fontSize="small"
            color="success"
            sx={{
              position: "absolute",
              scale: copied ? 1 : 0,
              transition: ".2s ease",
            }}
          />
          <CopyAllOutlined
            fontSize="small"
            sx={{ scale: copied ? 0 : 1, transition: ".2s ease" }}
          />
        </IconButton>
      </Tooltip>
    );
  }
};

export default memo(CopyButtonText);
