import { Chat } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { APIActions } from "../../api/actions";
import TextInput from "../../components/inputs/text-input";
import CloseableDialogTitle from "../../modals/CloseableDialogTitle";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { removeExpense } from "../../store/features/expensesSlice";
import formatAmount from "../../utils/formatAmount";
import { curr } from "../../utils/more/currency_country";
import { CurrencyInputMask } from "../../utils/more/mask_functions";

const ApprovePartialWrapper = ({
  renderComponent = ({ openModal = () => {} }) => {},
  role = "approver",
  onSuccess = () => {},
}) => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(null);
  const [payload, setPayload] = useState({});
  const [discount, setDiscount] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const abortControllerRef = useRef(null);

  const [amountError, setAmountError] = useState(null);

  const { originalAmount, expenseId } = payload;

  const opened = Boolean(menu);

  const handleOpenModal = ({ target, payload }) => {
    setMenu(target);
    setPayload(payload || {});
  };
  const onClose = () => {
    setMenu(null);
  };

  const total = useMemo(() => {
    return parseFloat(originalAmount?.amount || 0) - parseFloat(discount || 0);
  }, [originalAmount?.amount, discount]);

  const handleApply = async () => {
    if (total < 0) {
      setAmountError("Insira um valor maior que R$0,00");
      return;
    }
    setAmountError(null);
    setLoading(true);
    try {
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      const expense = await APIActions.expenses.approvePartial({
        expenseId,
        reason,
        amount: parseFloat(discount || 0),
        role,
        signal: abortController.signal,
      });

      dispatch(
        removeExpense({
          id: expenseId,
          role: "approver",
        })
      );
      dispatch(
        openSnackbar({
          message: "Despesa aprovada",
        })
      );
      onSuccess(expense);
      onClose();
    } catch (error) {
      dispatch(setError({ error }));
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    onClose();
  };

  return (
    <>
      {renderComponent({ openModal: handleOpenModal })}
      {opened && (
        <Popover
          open={opened}
          anchorEl={menu}
          onClose={onClose}
          transitionDuration={0}
          slotProps={{
            paper: {
              sx: {
                width: 400,
                borderRadius: 5,
                bgcolor: "elevation1.main",
                boxShadow: 2,
                pointerEvents: "auto",
              },
            },
            transition: {
              unmountOnExit: true,
            },
          }}
          hideBackdrop
          style={{ pointerEvents: "none" }}
        >
          <CloseableDialogTitle onClose={onClose}>
            Aprovar despesa
          </CloseableDialogTitle>
          <DialogContent sx={{ pb: 5 }}>
            <TextInput
              readOnly={loading}
              autoFocus
              label={"Com um desconto de:"}
              inputComponent={CurrencyInputMask}
              LeftIcon={() => curr(originalAmount?.currency)}
              placeholder={"0,00"}
              inputSx={{ fontSize: "1.5rem" }}
              value={discount}
              onChange={setDiscount}
              variant={"standard"}
              error={Boolean(amountError)}
              helperText={amountError || ""}
            />
            <Stack mt={3} alignItems={"flex-start"}>
              <Box flex={1} />
              <Typography
                gutterBottom
                fontWeight={500}
                fontSize={"1rem"}
                color="textPrimary"
              >
                Valor a ser aprovado
              </Typography>
              <Typography
                color="textSecondary"
                fontWeight={600}
                gutterBottom
                fontSize={".9rem"}
              >
                {curr(originalAmount?.currency)}{" "}
                {formatAmount(originalAmount?.amount)} -{" "}
                {curr(originalAmount?.currency)} {formatAmount(discount)}
              </Typography>
              <Typography color="primary" fontWeight={600} fontSize={"1.4rem"}>
                = {curr(originalAmount?.currency)} {formatAmount(total)}
              </Typography>
              <TextInput
                readOnly={loading}
                sx={{ mt: 2 }}
                value={reason}
                onChange={setReason}
                LeftIcon={Chat}
                variant={"filled"}
                hiddenLabel
                multiline
                size={"small"}
                placeholder={"Observação"}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ boxShadow: "0px 0px 4px rgba(0,0,0,.1)" }}>
            <Button onClick={handleCancel}>Cancelar</Button>
            <Button
              loading={loading}
              onClick={handleApply}
              disabled={loading}
              variant="contained"
            >
              Aprovar
            </Button>
          </DialogActions>
        </Popover>
      )}
    </>
  );
};

export default memo(ApprovePartialWrapper);
