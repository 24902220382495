import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import authBackground from "../../assets/bgs/auth-background.png";
import PartyLottie from "../../assets/lotties/party.json";
import NotepayLogo from "../../components/NotepayLogo";
import { setIsFirstAccess } from "../../store/features/base/settingsSlice";
import StepContent from "./components/StepContent";

const ModalWelcome = () => {
  const dispatch = useDispatch();
  const isFirstAccess = useSelector((state) => state.settings.isFirstAccess);

  const [mounted, setMounted] = useState(false);
  const [open, setOpen] = useState(false);
  // const [step, setStep] = useState(1);

  useEffect(() => {
    let timeout;
    if (isFirstAccess) {
      timeout = setTimeout(() => {
        setOpen(true);
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (open) {
      timeout = setTimeout(() => {
        setMounted(true);
      }, 900);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    dispatch(setIsFirstAccess(false));
  };

  return (
    <Dialog
      open={open}
      fullWidth
      transitionDuration={200}
      maxWidth="xs"
      slotProps={{
        transition: { unmountOnExit: true },
        paper: {
          sx: {
            position: "relative",
            height: "100%",
            maxHeight: "30em",
            overflow: "hidden",
            background: (t) =>
              `${t.palette.elevation1.main} url(${authBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "190%",
            backgroundPosition: "center",
            zIndex: 1000,
          },
        },
      }}
    >
      {mounted && (
        <Lottie
          options={{
            animationData: PartyLottie,
            autoplay: true,
            loop: false,
          }}
          width={600}
          style={{
            position: "absolute",
            bottom: -30,
            left: -50,
          }}
        />
      )}
      <IconButton
        onClick={handleClose}
        disableTouchRipple
        color="inherit"
        sx={{ position: "absolute", top: 7, right: 7, transition: "none" }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <StepContent
          topContent={
            <NotepayLogo sx={{ width: "90px", height: "90px", boxShadow: 3 }} />
          }
          title={`Bem-vindo à NotePay`}
          description={`Simplifique a gestão de despesas corporativas e tenha mais controle sobre os gastos da sua empresa.`}
        />
      </DialogContent>
      <DialogActions sx={{ height: 80 }}>
        <Box flex={1} />
        <Button onClick={handleClose} variant="contained" disableElevation>
          Vamos lá
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ModalWelcome);
