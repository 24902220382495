import { InfoRounded } from "@mui/icons-material";
import { InputAdornment, TextField, Typography } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";

function Input({
  label,
  width,
  className,
  type = "text",
  LeftIcon,
  maxLength,
  RightIcon,
  size,
  placeholder,
  variant,
  helperText = "",
  onChange = () => {},
  onBlur,
  onFocus = () => {},
  clearError,
  disableErrorOnBlur,
  required,
  inputComponent,
  multiline,
  autoFocus,
  readOnly,
  sx,
  value,
  maxRows,
  onClick,
  inputMode,
  rows,
  disabled,
  accept,
  onKeyDown,
  margin,
  id,
  inputRef,
  hiddenLabel,
  activeInternalValue,
  inputSx,
  spellCheck,
  error,
  disableUnderline,
}) {
  const [internalError, setInternalError] = useState(Boolean(error));

  useEffect(() => {
    setInternalError(Boolean(error));
  }, [Boolean(error)]);

  const handleBlur = () => {
    const curValue = value;
    if (required && !disableErrorOnBlur) {
      if ((typeof curValue === "string" && !curValue.trim()) || !curValue) {
        setInternalError(true);
      }
    }
    onBlur && onBlur();
  };

  const handleFocus = useCallback(() => {
    onFocus();
  }, []);

  const handleChange = useCallback(
    (e) => {
      onChange(e.target.value);
      setInternalError(false);
      if (clearError && typeof clearError === "function") {
        clearError();
      }
    },
    [activeInternalValue, clearError]
  );

  return (
    <TextField
      accept={accept}
      id={id}
      sx={{
        width: width || "100%",
        ...sx,
      }}
      margin={margin}
      inputRef={inputRef}
      onKeyDown={onKeyDown}
      disabled={disabled}
      inputMode={inputMode}
      onClick={onClick}
      placeholder={placeholder}
      className={className}
      autoFocus={autoFocus}
      type={type}
      required={required}
      label={label}
      size={size}
      onChange={handleChange}
      value={value}
      hiddenLabel={hiddenLabel}
      variant={variant}
      error={internalError}
      onFocus={handleFocus}
      onBlur={handleBlur}
      maxRows={maxRows}
      rows={rows}
      multiline={multiline}
      spellCheck={spellCheck}
      helperText={
        internalError &&
        Boolean(helperText) && (
          <Typography
            component={"span"}
            ml={-1}
            display={"flex"}
            alignItems={"flex-start"}
            variant="inherit"
            fontWeight={"500"}
            fontSize={".83rem"}
          >
            <InfoRounded
              color="error"
              sx={{ mr: 1, mt: 0.5, fontSize: "1rem" }}
            />
            {helperText}
          </Typography>
        )
      }
      slotProps={{
        input: {
          disableUnderline,
          sx: {
            fontWeight: "500",
            ...inputSx,
          },
          inputComponent: inputComponent,
          startAdornment: LeftIcon && (
            <InputAdornment position={"start"}>
              <LeftIcon fontSize={size} />
            </InputAdornment>
          ),
          endAdornment: RightIcon && (
            <InputAdornment position={"end"}>
              <RightIcon fontSize={size} />
            </InputAdornment>
          ),
        },
        htmlInput: {
          maxLength,
          readOnly,
        },
      }}
    />
  );
}
export default memo(Input);
