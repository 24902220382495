import { Stack, Typography } from "@mui/material";
import React, { memo } from "react";

const StepContent = ({ topContent, title, description }) => {
  return (
    <Stack height={"100%"} justifyContent={"center"} alignItems={"center"}>
      {topContent}
      <Typography mt={3} gutterBottom fontWeight={"600"} variant="h4">
        {title}
      </Typography>
      <Typography
        maxWidth={330}
        textAlign={"center"}
        fontWeight={"500"}
        color="textSecondary"
        fontSize={"1.1rem"}
        variant="body1"
      >
        {description}
      </Typography>
    </Stack>
  );
};

export default memo(StepContent);
