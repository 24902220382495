import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../../api/httpClient";

const baseName = "integrators";

const initialState = {
  sap: {
    status: "idle",
    error: null,
    configured: false,
    data: {
      active: false,
    },
  },
  senior: {
    status: "idle",
    error: null,
    configured: false,
    data: {
      active: false,
    },
  },
};

export const getSapInfo = createAsyncThunk(
  `${baseName}/getSapInfo`,
  async () => {
    const res = await HttpClient.get({
      url: "/admin/sap",
    });
    return res?.data;
  }
);
export const getSeniorInfo = createAsyncThunk(
  `${baseName}/getSeniorInfo`,
  async () => {
    const res = await HttpClient.get({
      url: "/admin/senior",
    });
    return res?.data;
  }
);

export const integratorsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupSapInfo(state, { payload }) {
      const { data } = payload || {};
      if (!data) {
        state.sap.configured = false;
        state.sap.data.active = false;
      } else {
        state.sap.configured = true;
        state.sap.data = {
          active: true,
          ...(data || {}),
        };
      }
    },
    updateSapInfo(state, { payload }) {
      state.sap.data = {
        ...state.sap.data,
        ...(payload || {}),
      };
    },
    setupSeniorInfo(state, { payload }) {
      const { data } = payload || {};
      if (!data) {
        state.senior.configured = false;
        state.senior.data.active = false;
      } else {
        state.senior.configured = true;
        state.senior.data = {
          ...(data || {}),
          active: true,
        };
      }
    },
    updateSeniorInfo(state, { payload }) {
      state.senior.data = {
        ...state.senior.data,
        ...(payload || {}),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSapInfo.pending, (state, action) => {
        state.sap.status = "loading";
        state.sap.error = null;
      })
      .addCase(getSapInfo.rejected, (state, action) => {
        state.sap.status = "failed";
        state.sap.error = action.error.message || null;
      })
      .addCase(getSapInfo.fulfilled, (state, action) => {
        state.sap.status = "succeeded";
        state.sap.error = null;

        //Dados API
        const data = action.payload || null;
        integratorsSlice.caseReducers.setupSapInfo(state, {
          payload: {
            data,
          },
        });
      })
      .addCase(getSeniorInfo.pending, (state, action) => {
        state.senior.status = "loading";
        state.senior.error = null;
      })
      .addCase(getSeniorInfo.rejected, (state, action) => {
        state.senior.status = "failed";
        state.senior.error = action.error.message || null;
      })
      .addCase(getSeniorInfo.fulfilled, (state, action) => {
        state.senior.status = "succeeded";
        state.senior.error = null;

        //Dados API
        const data = action.payload || null;
        integratorsSlice.caseReducers.setupSeniorInfo(state, {
          payload: {
            data,
          },
        });
      });
  },
});

export const {
  setupSapInfo,
  updateSapInfo,
  setupSeniorInfo,
  updateSeniorInfo,
} = integratorsSlice.actions;

export default integratorsSlice.reducer;

export const selectSapState = createSelector(
  [(state) => state.integrators.sap],
  (sapState = {}) => {
    const prevData = sapState || { status: "idle", error: null, data: {} };
    return {
      ...prevData,
    };
  }
);
export const selectSeniorState = createSelector(
  [(state) => state.integrators.senior],
  (seniorState = {}) => {
    const prevData = seniorState || { status: "idle", error: null, data: {} };
    return {
      ...prevData,
    };
  }
);
