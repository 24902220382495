import { lazy } from "react";

const Users = lazy(() => import("../pages/admin/users"));
const Branches = lazy(() => import("../pages/admin/branches"));
const Groups = lazy(() => import("../pages/admin/groups"));
const Categories = lazy(() => import("../pages/admin/categories"));
const Rules = lazy(() => import("../pages/admin/rules"));
const Audit = lazy(() => import("../pages/admin/audit"));
const Payments = lazy(() => import("../pages/admin/payments"));
const Vehicles = lazy(() => import("../pages/admin/vehicles"));
const RoutePolicies = lazy(() => import("../pages/admin/route-policies"));
const Projects = lazy(() => import("../pages/admin/projects"));
const AccountsPlan = lazy(() => import("../pages/admin/accounts-plan"));
const AdvancedSettings = lazy(() => import("../pages/admin/advanced-settings"));
const Integrations = lazy(() => import("../pages/admin/integrations"));
const IntegrationView = lazy(() =>
  import("../pages/admin/integrations/view/IntegrationView")
);
const APIRestIntegration = lazy(() =>
  import("../pages/admin/api-rest-integration")
);

export const ADMIN_ROUTES = [
  {
    path: "users",
    element: <Users />,
    children: [],
  },
  {
    path: "branches",
    element: <Branches />,
    children: [],
  },
  {
    path: "groups",
    element: <Groups />,
    children: [],
  },
  {
    path: "categories",
    element: <Categories />,
    children: [],
  },
  {
    path: "rules",
    element: <Rules />,
    children: [],
  },
  {
    path: "audit",
    element: <Audit />,
    children: [],
  },
  {
    path: "route-policies",
    element: <RoutePolicies />,
    children: [],
  },
  {
    path: "payment-methods",
    element: <Payments />,
    children: [],
  },
  {
    path: "vehicles",
    element: <Vehicles />,
    children: [],
  },
  {
    path: "projects",
    element: <Projects />,
    children: [],
  },
  {
    path: "accounts-plan",
    element: <AccountsPlan />,
    children: [],
  },
  {
    path: "advanced",
    element: <AdvancedSettings />,
    children: [],
  },
  {
    path: "integrations",
    element: <Integrations />,
    children: [
      {
        path: ":integrationId",
        element: <IntegrationView />,
        children: [],
      },
    ],
  },
  {
    path: "api-rest",
    element: <APIRestIntegration />,
    children: [],
  },
];
