import { Close } from "@mui/icons-material";
import { Button, IconButton, Snackbar, Typography } from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "../../store/features/base/snackbarBaseSlice";

function SnackbarBase(props) {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.snackbarBase);

  const { open, message, action, actionText, error, duration, hiddenClose } =
    data || {};

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      autoHideDuration={duration || 4000}
      onClose={handleClose}
      data-disableselect={true}
      transitionDuration={50}
      sx={{ pl: "5em" }}
      slotProps={{
        content: {
          sx: {
            boxShadow: 0,
            bgcolor: error && "error.main",
          },
        },
      }}
      message={
        <Typography variant="inherit" fontSize={".92rem"} fontWeight={"600"}>
          {message}
        </Typography>
      }
      action={
        <>
          {action && actionText && (
            <Button
              sx={{
                color: error ? "#FFF" : "primaryDark.main",
                ":hover": { textDecoration: "underline" },
              }}
              onClick={() => {
                action();
                handleClose();
              }}
            >
              {actionText || ""}
            </Button>
          )}
          {!hiddenClose && open && (
            <IconButton
              LinkComponent={"button"}
              autoFocus
              sx={{
                transition: "none",
                ":hover":{
                  bgcolor: '#FFFFFF20'
                }
              }}
              onClick={handleClose}
              color="inherit"
            >
              <Close />
            </IconButton>
          )}
        </>
      }
    />
  );
}

export default memo(SnackbarBase);
