import { Close, ElectricalServicesOutlined, Power } from "@mui/icons-material";
import {
  Chip,
  darken,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import CopyIconButton from "../../../../../../components/buttons/CopyIconButton";

const SapIntegration = ({ sapDocument = "", type = "expense", entityId }) => {
  const [menu, setMenu] = useState(null);
  return (
    <>
      <Tooltip title="Documento de integração">
        <Chip
          variant="filled"
          icon={<Power />}
          color="primary"
          label={type === "expense" ? "Integrada" : "Integrado"}
          clickable
          size="small"
          onClick={(e) => setMenu(e.target)}
          sx={{
            bgcolor: (t) => "elevation1.main",
            fontWeight: 600,
            height: 30,
            fontSize: ".9rem",
            color: "primary.main",
            transition: "none",
            px: 1,
            ":hover": {
              bgcolor: (t) => darken(t.palette.elevation1.main, 0.08),
            },
          }}
        />
      </Tooltip>
      <Popover
        transitionDuration={0}
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={() => setMenu(null)}
        slotProps={{
          paper: {
            sx: {
              width: 280,
              position: "relative",
              p: 2,
              borderRadius: 5,
            },
          },
        }}
      >
        <IconButton
          onClick={() => setMenu(null)}
          sx={{ position: "absolute", top: 5, right: 5 }}
        >
          <Close />
        </IconButton>
        <ElectricalServicesOutlined
          sx={{ fontSize: "2rem", mt: 1 }}
          fontSize="large"
          color="primary"
        />
        <Typography
          gutterBottom
          mt={1}
          variant="h5"
          fontSize={"1.3rem"}
          fontWeight={"600"}
        >
          {type === "expense"
            ? "Despesa integrada com sucesso"
            : "Adiantamento integrado com sucesso"}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          fontSize={".92rem"}
          fontWeight={"500"}
          mb={1}
        >
          Um número de documento de integração foi gerado:
        </Typography>
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Typography fontSize={"1rem"} color="primary" fontWeight={"600"}>
            {sapDocument}
          </Typography>
          <CopyIconButton value={sapDocument} />
        </Stack>
      </Popover>
    </>
  );
};

export default memo(SapIntegration);
