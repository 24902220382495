import { ModeOfTravel } from "@mui/icons-material";
import React, { memo } from "react";
import Baseline from "../../../../components/form/Baseline";
import TextInput from "../../../../components/inputs/text-input";
import formatAmount from "../../../../utils/formatAmount";
import { NumberInputMask } from "../../../../utils/more/mask_functions";
import SelectorBox from "../SelectorBox";

function DistanceInput({
  value = "",
  readOnly,
  onChange,
  size = "small",
  margin,
  isEditable = true,
  variant,
}) {
  if (!isEditable) {
    return (
      <Baseline
        Icon={ModeOfTravel}
        label={"Distância"}
        value={`${formatAmount(value)} km`}
      />
    );
  } else {
    return (
      <SelectorBox Icon={ModeOfTravel} active={Boolean(value)}>
        <TextInput
          size={size}
          placeholder={"Distância em km"}
          fullWidth
          variant={variant}
          margin={margin}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          inputComponent={NumberInputMask}
          RightIcon={() => 'Km'}
        />
      </SelectorBox>
    );
  }
}

export default memo(DistanceInput);
